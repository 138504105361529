import React, { Fragment } from 'react';
import Tag from 'components/tag';
import classNames from 'classnames';

import lightImage from './loading-light.png';
import darkImage from './loading-dark.png';

export const Loading = (props) => (
    <Fragment>
        <Tag.img src={lightImage} alt="loading" className={classNames('loading-img loading-light', props.className)} />
        <Tag.img src={darkImage} alt="loading" className={classNames('loading-img loading-dark', props.className)} style={{ display: 'none' }} />
    </Fragment>
);
