import { IMG_FULL_TYPE } from "components/user-enter/schema";

// 非展示字符
const SILENCE_CHAR = '\u200B';
const SILENCE_CHAR_RE = new RegExp(SILENCE_CHAR, 'g');

/**
 * 使用 <br> 来换行
 * 不同浏览器的默认换行不一致，有的是 p，有的是 div
 * @todo IE 插入两个 <br> 后，会空出多一行，一个 <br> 却不换行
 */
export function insertBr() {
    // if (document.queryCommandSupported('defaultParagraphSeparator')) {
    //     document.execCommand('defaultParagraphSeparator', false, 'br');
    // } else {}

    // Chrome 不支持 defaultParagraphSeparator 指令
    // 某些浏览器插入两个br会展示多个换行
    // 使用br + 隐藏字符的方式改变聚焦行
    insertHtml(`<br/>${SILENCE_CHAR}`);
}

// 清理掉换行时插入的非展示字符
export function cleanSileceUnicode(str) {
    return str.replace(SILENCE_CHAR_RE, '');
}

/**
 * 获取光标
 * @param element
 * @return {Array}
 */
export function getAllRange(element) {
    const selection = window.getSelection();

    let index;
    const RangeCollection = [];

    for (index = 0; index < selection.rangeCount; index++) {
        const range = selection.getRangeAt(index);

        // 如果焦点不在目标元素中，不设置光标
        if (!element.contains(range.startContainer) || !element.contains(range.endContainer)) {
            break;
        }

        RangeCollection.push({
            startContainer: range.startContainer,
            startOffset: range.startOffset,
            endContainer: range.endContainer,
            endOffset: range.endOffset
        });
    }

    // console.log('[getAllRange]', RangeCollection, selection);

    return RangeCollection;
}

/**
 * 设置光标
 * @param [RangeCollection] 当 RangeCollection 不存在的时候，模拟 focus 行为
 * @param element
 */
export function setRange(RangeCollection = [], element) {
    // console.log('[setRange]', RangeCollection, element);

    const selection = window.getSelection();
    selection.removeAllRanges();

    const range = document.createRange();

    // 模拟 focus 行为
    if (RangeCollection.length === 0) {
        // element.focus();

        // const rangeInfo = selection.getRangeAt(0);

        // console.log('origin range:', rangeInfo);

        // range.setStart(rangeInfo.startContainer, rangeInfo.startOffset);
        // range.setEnd(rangeInfo.endContainer, rangeInfo.endOffset);

        // range.selectNode(element.childNodes[0]);
        range.setStart(element, 0);
        range.setEnd(element, 0);
        range.collapse();

        // console.log('create range:', range);

        selection.addRange(range);

        return;
    }

    // 还原选区
    RangeCollection.forEach((rangeInfo) => {
        // 如果焦点不在目标元素中，不设置光标
        if (!element.contains(rangeInfo.startContainer) || !element.contains(rangeInfo.endContainer)) {
            return;
        }


        range.setStart(rangeInfo.startContainer, rangeInfo.startOffset);
        range.setEnd(rangeInfo.endContainer, rangeInfo.endOffset);

        selection.addRange(range);
    });
}

/**
 * 移动光标到末尾
 * @param containerElement contenteditable 的元素
 */
export function getLastChildRange(containerElement) {
    const range = document.createRange();
    range.selectNodeContents(containerElement);
    range.collapse(false);
    return range;
}

/**
 * 插入 HTML 字符串
 * @param {*} html
 */
export function insertHtml(html) {
    const COMMAND = 'insertHTML';

    if (document.queryCommandSupported(COMMAND)) {
        document.execCommand(COMMAND, false, html);
    } else {
        // IE 不支持 execCommand('inertHTML'), 采用兼容方案
        pasteHtmlAtCaret(html, false);
    }
}

/**
 * 在 光标位置 插入 html 片段
 * IE 不支持 execCommand('inertHTML')
 * https://stackoverflow.com/questions/6690752/insert-html-at-caret-in-a-contenteditable-div/6691294
 * @param {string} html
 * @param {boolean} [selectPastedContent]
 */
function pasteHtmlAtCaret(html, selectPastedContent) {
    let sel;
    let range;
    if (window.getSelection) {
        // IE9 and non-IE
        sel = window.getSelection();
        if (sel.getRangeAt && sel.rangeCount) {
            range = sel.getRangeAt(0);
            range.deleteContents();

            // Range.createContextualFragment() would be useful here but is
            // only relatively recently standardized and is not supported in
            // some browsers (IE9, for one)
            const el = document.createElement('div');
            el.innerHTML = html;
            const frag = document.createDocumentFragment();
            let node;
            let lastNode;
            while ((node = el.firstChild)) {
                lastNode = frag.appendChild(node);
            }
            const firstNode = frag.firstChild;
            range.insertNode(frag);

            // Preserve the selection
            if (lastNode) {
                range = range.cloneRange();
                range.setStartAfter(lastNode);
                if (selectPastedContent) {
                    range.setStartBefore(firstNode);
                } else {
                    range.collapse(true);
                }
                sel.removeAllRanges();
                sel.addRange(range);
            }
        }
    } else if ((sel = document.selection) && sel.type !== 'Control') {
        // IE < 9
        const originalRange = sel.createRange();
        originalRange.collapse(true);
        sel.createRange().pasteHTML(html);
        if (selectPastedContent) {
            range = sel.createRange();
            range.setEndPoint('StartToStart', originalRange);
            range.select();
        }
    }
}

export function checkImageType(file) {
    return new Promise((resolve, reject) => {
        IMG_FULL_TYPE.test(file.type) ? resolve(file) : reject(Error('暂不支持解析剪切板中的文件'));
    });
}

/**
 * 跨平台 PasteEvent 处理
 * @param {Event} event
 * @param {pasteCallback} callback - 剪切事件的回调函数
 *
 * @callback pasteCallback
 * @param {string} context // 剪切板中的字符串
 * @param {File} [image] // 剪切板中的图片文件
 */
export function handlePasteEvent(event, callback) {
    // chrome 72+ 的异步黏贴板 API, 最新文档
    const syncClipboardData = navigator.clipboard;
    // 标准事件的黏贴版 API, 旧文档
    const standardClipboardData = event.clipboardData;
    // IE 私有的黏贴版 API, 祖传文档
    const ieClipboardData = window.clipboardData;

    const files = (standardClipboardData.files || [])[0];
    const text = standardClipboardData.getData('Text');

    if (files) {
        event.preventDefault();
        checkImageType(standardClipboardData.files[0])
            .then((image) => callback('', image))
            .catch(console.warn);
        return;
    }

    if (text) {
        event.preventDefault();
        callback(text);
        return;
    }

    // Firefox 支持 navigator.clipboard 但还未支持 navigator.clipboard.readText
    if (syncClipboardData && typeof syncClipboardData.readText === 'function') {
        event.preventDefault();
        syncClipboardData.readText().then(callback);
        return;
    }

    // 优先判断 IE，IE 11 支持 event.clipboardData 和 window.clipboardData
    // 但 IE 11 的 event.clipboardData.getData 的参数只支持 text 而不是 text/plain
    if (ieClipboardData) {
        event.preventDefault();
        Promise.resolve(ieClipboardData.getData('text')).then(callback);
        return;
    }

    if (standardClipboardData) {
        event.preventDefault();
        Promise.resolve(standardClipboardData.getData('text/plain')).then(callback);
        return;
    }

    console.log('当前环境不支持各种 clipboard API, fallback 到默认行为');
}
