/* eslint-disable camelcase */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getPlatform } from 'components/util';
import {
  getAllRange,
  handlePasteEvent,
  insertBr,
  insertHtml,
  setRange,
  getLastChildRange,
  cleanSileceUnicode,
} from './util';

import './style.less';

const IS_ANDROID = /android/i.test(navigator.userAgent);
const IS_IOS = /iphone|ipad|ipod/i.test(navigator.userAgent);
/**
 * 兔小巢的富文本编辑器
 * 监听了 input\keydown\keyup\paste 等输入事件
 * Ref 暴露了 insertImage 、 getValue 、 reset 3 个 API
 *  - insertImage 用来在当前光标位置插入自定义表情
 *  - getValue 用来返回所有格式化后的输入内容
 *  - reset 来重置输入区域内容
 *  - focus 获取焦点，移动端用来拉起键盘
 *  - blur 放弃焦点，移动端用来收起键盘
 *  - backspace 退格
 */
export class Editor extends Component {
  constructor(props) {
    super(props);

    // 光标暂存区
    this.RangeCollection = [];

    this.inputEle = React.createRef();
  }

  componentDidMount() {
    this.listenerKeyboardChange();
    // pc端的回复和评论点开始直接获取焦点
    if (getPlatform() === 'others') {
      this.inputEle.current.focus({ preventScroll: true });
    }
    this.setDefaultValue();
  }

  /**
   * 监听 input 行为，并触发 render
   * @param event
   */
  onInput() {
    this.props.onChange();
  }

  /**
   * 拦截一些用户的键盘输入
   * @param event
   */
  onKeyDown(event) {
    const { key, keyCode, isComposing } = event.nativeEvent;
    // 判断是否为真实的换行输入
    // keyCode值 为 229时为中文输入的回车（新版浏览器中keyCode被弃用）
    // isComposing 补全新版浏览器中文输入回车键判断
    const isRealEnter = keyCode !== 229 && !isComposing;

    switch (key) {
      case 'Enter':
        // 对齐老版本，禁止输入回车
        event.preventDefault();
        // 如果是用户真实的回车操作 插入br标签
        isRealEnter && insertBr();
        break;
      default:
        break;
    }

    this.props.onChange();
  }

  /**
   * IE 不支持 contenteditable 的 input，需要用 keyup 触发 render
   * @param event
   */
  onKeyUp() {
    //
  }

  /**
   * 监听黏贴行为
   * @param event
   */
  onPaste(event) {
    // todo paste 里面有一段耦合的内容
    handlePasteEvent(event, (content, image) => {
      this.props.onPasteTransform(content, image);
      this.props.onChange();
    });
  }

  /**
   * 失焦的时候，保存焦点
   * @param event
   */
  onBlur() {
    const { onBlur } = this.props;
    this.saveCursorPosition();
    if (typeof onBlur === 'function') {
      onBlur();
    }
  }

  /**
   * 恢复焦点
   * @param event
   */
  onFocus() {
    this.restoreCursorPosition();
  }

  /**
   * 保存光标
   * @param {Array} [ranges] 选区，如果没有，则从当前的 selection 中获取
   */
  saveCursorPosition(ranges) {
    const rangeCollection = ranges || getAllRange(this.inputEle.current);
    if (rangeCollection.length > 0) {
      this.RangeCollection = rangeCollection;

      // console.log('save cursor', this.RangeCollection);
    }
  }

  /**
   * 还原光标
   */
  restoreCursorPosition() {
    if (this.RangeCollection.length !== 0) {
      // console.log('restoreCursorPosition type: setRange');

      setRange(this.RangeCollection, this.inputEle.current);
    } else {
      // console.log('restoreCursorPosition type: focus');

      this.inputEle.current.focus();
    }
  }

  /**
   * 监听键盘行为
   * 仅对 iOS 和 Android 有效
   */
  listenerKeyboardChange() {
    if (IS_ANDROID) {
      const originHeight = document.documentElement.clientHeight;

      window.addEventListener(
        'resize',
        (event) => {
          const resizeheight = document.documentElement.clientHeight;

          // console.log('resize documentElement.clientHeight:', document.documentElement.clientHeight);

          if (resizeheight < originHeight) {
            // console.log('Android 键盘已打开', event);
            this.props.onKeyboardOpen(event);
          } else {
            // console.log('Android 键盘已收起', event);
            this.props.onKeyboardClose(event);
          }
        },
        false,
      );
    }

    if (IS_IOS) {
      this.inputEle.current.addEventListener(
        'focus',
        (event) => {
          // console.log('iOS 键盘已打开', event);
          this.props.onKeyboardOpen(event);
        },
        false,
      );

      this.inputEle.current.addEventListener(
        'blur',
        (event) => {
          // console.log('iOS 键盘已收起', event);
          this.props.onKeyboardClose(event);
        },
        false,
      );
    }
  }

  /**
   * 插入图片
   * 外部通过 https://zh-hans.reactjs.org/docs/refs-and-the-dom.html#adding-a-ref-to-a-class-component 方式调用
   */
  insertImage(img) {
    // 插入表情前，还原光标位置
    this.restoreCursorPosition();

    // 插入后，光标会后移
    insertHtml(img);

    // 更新光标位置
    this.saveCursorPosition();

    this.props.onChange();
  }

  /**
   * 移动端操作光标，会导致键盘弹起
   * 先沿用旧方案插入到末尾
   * @param {string} img <img> 标签的字符串内容
   */
  UNSAFE_appendImage(img) {
    let { innerHTML } = this.inputEle.current;

    const [lastRange] = this.RangeCollection;

    // 移动光标到末尾
    const moveRangeToLast = getLastChildRange(this.inputEle.current);

    if (IS_ANDROID && lastRange) {
      // 存在光标，插入到光标位置
      // iOS Safari 的 Range 和标准有差异，暂时只开启 Android 的能力
      this.insertImageToRange(lastRange, img);
    } else {
      // 不存在光标，插入到末尾
      if (/<br>$/.test(innerHTML)) {
        innerHTML = innerHTML.replace(/<br>$/, `${img}<br>`);
      } else {
        innerHTML += img;
      }

      this.inputEle.current.innerHTML = innerHTML;

      // 保存光标，便于下次输入内容时候，可以还原光标位置
      this.saveCursorPosition([moveRangeToLast]);
    }

    this.props.onChange();
  }

  /**
   * 插入光标到 range 区域
   * @param {Range} range
   * @param {string} img
   */
  insertImageToRange(range, img) {
    // 代插入的 node 节点
    let replacementNode;

    const parseImage = (img) => {
      const div = document.createElement('div');
      div.innerHTML = img;
      return div.firstChild;
    };

    switch (range.startContainer.nodeType) {
      case Node.TEXT_NODE:
      case Node.COMMENT_NODE:
      case Node.CDATA_SECTION_NODE:
        replacementNode = range.startContainer.splitText(range.startOffset);
        break;
      case Node.ELEMENT_NODE:
        replacementNode = range.startContainer.childNodes[range.startOffset];
        break;
      default:
        break;
    }

    const imgNode = parseImage(img);

    // 插入节点
    this.inputEle.current.insertBefore(imgNode, replacementNode);

    // 移动光标到图片后面
    const moveRangeToLast = document.createRange();

    // 找到移动光标所需的偏移量
    const childNodes_array = Array.from(this.inputEle.current.childNodes);
    const index = childNodes_array.findIndex(node => node === imgNode);

    moveRangeToLast.setStart(this.inputEle.current, index + 1);
    moveRangeToLast.setEnd(this.inputEle.current, index + 1);
    moveRangeToLast.collapse(false);

    this.saveCursorPosition([moveRangeToLast]);
  }

  /**
   * 返回当前内容
   * 外部通过 https://zh-hans.reactjs.org/docs/refs-and-the-dom.html#adding-a-ref-to-a-class-component 方式调用
   */
  getValue() {
    if (!this.inputEle.current) {
      return '';
    }

    return cleanSileceUnicode(this.inputEle.current.innerHTML);
  }

  /**
   * 重置所有状态
   * 外部通过 https://zh-hans.reactjs.org/docs/refs-and-the-dom.html#adding-a-ref-to-a-class-component 方式调用
   */
  reset() {
    this.RangeCollection = [];
    this.inputEle.current.innerHTML = '';
    this.props.onChange();
  }

  /**
   * 获取焦点，移动端拉起键盘
   */
  focus() {
    this.inputEle.current.focus();
  }

  /**
   * 放弃焦点，移动端收起键盘
   */
  blur() {
    this.inputEle.current.blur();
  }

  /**
   * 退格
   */
  backspace() {
    this.inputEle.current.innerHTML = this.props.onBackspace(this.inputEle.current.innerHTML);
    this.props.onChange();
  }

  /**
   * 设置输入框初始值
   */
  setDefaultValue() {
    if (this.props.defaultValue) {
      this.inputEle.current.innerHTML = this.props.defaultValue;
    }
  }

  render() {
    const { props } = this;

    return (
      <Fragment>
        <div
          ref={this.inputEle}
          className={classNames('editor', props.className)}
          contentEditable={!props.disabled}
          placeholder={props.placeholder}
          onFocus={event => this.onFocus(event)}
          onBlur={event => this.onBlur(event)}
          onKeyDown={event => this.onKeyDown(event)}
          onKeyUp={event => this.onKeyUp(event)}
          onInput={event => this.onInput(event)}
          onPaste={event => this.onPaste(event)}
          role="textbox"
          tabIndex="0"
        />
      </Fragment>
    );
  }
}

Editor.propTypes = {
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  /**
   * 对输入的内容进行格式化
   */
  onPasteTransform: PropTypes.func,
  /**
   * 监听编辑器blur 事件
   */
  onBlur: PropTypes.func,
  /**
   * 键盘弹起
   */
  onKeyboardOpen: PropTypes.func,
  /**
   * 键盘收起
   */
  onKeyboardClose: PropTypes.func,
  /**
   * 内容发生变化
   */
  onChange: PropTypes.func,
  /**
   *
   */
  onBackspace: PropTypes.func,
};

Editor.defaultProps = {
  disabled: false,
  onPasteTransform(string, image) {
    return string;
  },
  onKeyboardOpen() { },
  onKeyboardClose() { },
  onChange() { },
  onBackspace() { },
};
