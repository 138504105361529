import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Taro from '@tarojs/taro';
import { ariaRole } from 'components/util/aria';
import { TcIconUploadImg } from 'components/IconSystem';
import Tag from 'components/tag';
import  './btn-image-select.less';

// 隐私授权，是否允许访问系统相册和相机
let isAgree = false;

export const BtnImageSelect = (props) => {
  const inputRef = React.useRef(null);

  const onClick = React.useCallback((event) => {
    if (isAgree) {
      inputRef.current.click();
      return;
    }

    Taro.showModal({
      content: '是否允许访问系统相册和相机，用于发帖/回帖中的附带图片？',
      confirmText: '允许',
      cancelText: '拒绝',
      success: (res) => {
          if (res.cancel) return;
          isAgree = true;
          inputRef.current.click();
      }
    });
  }, []);

  // iOS 版的 MSDK 不支持 input type=file 拉起相册
  if (isIOS() && isMSDK()) {
    return null;
  }

  return (
    <Tag.div className="btn-image-select">
      <Tag.label
        className="btn-image-select__trigger image-upload-trigger"
        onClick={onClick}
      >
        <TcIconUploadImg
          {...ariaRole('button')}
          aria-label="上传图片"
        />
      </Tag.label>

      <Tag.input
        ref={inputRef}
        className="btn-image-select__input"
        type="file"
        multiple={true}
        onChange={props.onChange}
        id="input-file-for-images"
        accept="image/*"
      />
    </Tag.div>
  );
};

BtnImageSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
};

const isIOS = () => /iphone os/i.test(navigator.userAgent);

const isMSDK = () => /msdk\/[\w]+/i.test(navigator.userAgent);
