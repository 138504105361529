import React from 'react';
import PropTypes from 'prop-types';
import Tag from 'components/tag';
import { Loading } from './loading';

import './style.less';

export class LoadMore extends React.PureComponent {

    static observerHandler(entries) {
        entries.forEach(entry => {

            if (!entry.isIntersecting) {
                return;
            }

            entry.target && entry.target.onShow();

        });
    }

    constructor(props) {
        super(props);
        this.bindElement = this.bindElement.bind(this);
        this.el = React.createRef();
    }

    componentDidMount() {
        this.bindElement();
    }



    componentWillUnmount() {
        LoadMoreObserver.unobserve(this.el.current);
    }

    bindElement() {
        const element = this.el.current;

        // 关联 props.onShow 到元素上
        element.onShow = this.props.onShow;

        LoadMoreObserver.observe(element);
    }

    render() {
        return (
            <Tag.div className="load-more" ref={this.el}>
                <Loading /> <Tag.span isText className="load-more-text">正在加载...</Tag.span>
            </Tag.div>
        );
    }

}

LoadMore.defaultProps = {
    onShow() { console.log('LoadMore 的 onShow 未绑定') }
};

LoadMore.propTypes = {
    onShow: PropTypes.func
};

const LoadMoreObserver = (function () {

    // 需要用 window.IntersectionObserver 而不能直接 IntersectionObserver，否则 iOS9 会报错
    // ReferenceError: Can't find variable: IntersectionObserver
    if (!window.IntersectionObserver) {

        console.log('LoadMore 依赖 IntersectionObserver API，当前 runtime 不包含该 API，请检查 polyfill');

        return {
            observe: function () {},
            unobserve: function () {}
        };
    }

    return new IntersectionObserver(LoadMore.observerHandler);

})();
